<template>
  <div ref="driverIntegralStat" class="driverIntegralStat">
    <!-- 操作栏 -->
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">积分日期：</div>
        <div class="col-2">
          <el-date-picker
            v-model="timeBucketValue"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">所属公司：</div>
        <div class="col-2">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">司机姓名：</div>
        <div class="col-2">
          <el-select
            v-model="form.driverIds"
            filterable
            remote
            reserve-keyword
            placeholder="请输入司机"
            clearable
            :remote-method="queryDriver"
            @clear="form.driverIds = null"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryData"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="tableExport"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="TableHeight"
    />
    <div ref="pagination" class="pagination">
      <div class="value">积分统计：{{ value }}</div>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getDriverList,
  getCompanyTree,
  exportDriverIntegralStatAPI,
  queryDriverIntegralStatPageAPI,
} from "@/api/lib/api.js";
import { lastTime } from "@/tools/getTime.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      tableData: [],
      titleName: [
        {
          title: "统计时间段",
          props: "timeBucket",
          width: 200,
        },
        {
          title: "公司",
          props: "companyName",
          width: 150,
        },
        {
          title: "司机手机号码",
          props: "driverPhone",
          width: 150,
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "积分增项",
          props: "addIntegral",
        },
        {
          title: "积分减项",
          props: "decreaseIntegral",
        },
        {
          title: "积分合计",
          props: "integral",
        },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      TableHeight: 0,
      driverList: [],
      total: 0,
      form: {
        integralTimeStart: null,
        integralTimeEnd: null,
        companyIds: null,
        driverIds: null,
        currentPage: 1,
        pageSize: 10,
      },
      value: 0,
      timeBucketValue: null,
      pickTime: null,
      pickerOptions: {
        disabledDate: (time) => {
          if (this.pickTime) {
            let number = 31 * 3600 * 24 * 1000;
            if (this.pickTime.getTime() + number > Date.now()) {
              return (
                time.getTime() > Date.now() ||
                time.getTime() < this.pickTime.getTime() - number
              );
            } else {
              return (
                time.getTime() > this.pickTime.getTime() + number ||
                time.getTime() < this.pickTime.getTime() - number
              );
            }
          } else {
            return time.getTime() > Date.now();
          }
        },
        onPick: ({ maxDate, minDate }) => {
          if (!maxDate && minDate) this.pickTime = minDate;
          else this.pickTime = null;
        },
      },
    };
  },
  mounted() {
    let day = new Date();
    let text =
      day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
    this.timeBucketValue = [lastTime(new Date()), text];
    this.getCompanyList();
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 点击导出按钮
    tableExport() {
      exportDriverIntegralStatAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "司机积分统计 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 表格数据渲染
    renderData() {
      this.form.integralTimeStart = this.timeBucketValue[0] + " 00:00:00";
      this.form.integralTimeEnd = this.timeBucketValue[1] + " 23:59:59";
      let obj = this.deepClone(this.form);
      Object.keys(obj).forEach((i) => {
        !obj[i] && delete obj[i];
      });
      queryDriverIntegralStatPageAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.value = res.data.value;
        }
      });
    },
    queryData() {
      this.form.currentPage = 1;
      this.renderData();
    },
    queryDriver(query) {
      this.driverList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            currentPage: 1,
            pageSize: 10,
            userName: query,
          };
          getDriverList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.driverList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 企业回显
    getAddGroupId(v) {
      if (v == null) {
        this.form.companyIds = [];
      } else {
        this.form.companyIds = [v];
      }
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.driverIntegralStat.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.driverIntegralStat {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  height: calc(100% - 32px);
  padding: 16px 20px;
  background: #ffffff;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  /deep/.el-date-editor.el-input__inner {
    width: 13vw !important;
  }
  /deep/.el-range-separator {
    line-height: 26px !important;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value {
      font-size: 16px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}
</style>
